var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c("section", { staticClass: "flex-1 ele-bwc-show-edit" }, [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("span", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("专场配置")
          ]),
          _c("el-divider", { attrs: { direction: "vertical" } }),
          _vm.setting.updated_at
            ? _c("small", { staticStyle: { color: "#999" } }, [
                _vm._v("更新时间：" + _vm._s(_vm.setting.updated_at))
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "d-flex align-center",
              staticStyle: {
                padding: "4px 12px 4px 8px",
                "margin-left": "48px",
                background: "#F2F3F5",
                "border-radius": "4px"
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-location",
                staticStyle: {
                  "font-size": "20px",
                  color: "#1890FF",
                  "margin-right": "4px"
                }
              }),
              _c("span", [_vm._v(_vm._s(_vm.setting.address))])
            ]
          ),
          _c(
            "div",
            { staticClass: "flex-1", staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v(_vm._s(!_vm.setting.id ? "创建" : "保存"))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "d-flex ele-bwc-show-edit__c" }, [
        _c("section", { staticClass: "flex-col ele-bwc-show-edit__w" }, [
          _c(
            "div",
            {
              staticClass: "d-flex",
              staticStyle: {
                padding: "10px 15px",
                "border-bottom": "1px solid #e5e5e5"
              }
            },
            [
              _c("p", { staticClass: "flex-1" }, [_vm._v("商家选择列表")]),
              _c(
                "p",
                {
                  style: {
                    color: _vm.shopLoadProgress.completed ? "green" : "red"
                  }
                },
                [_c("small", [_vm._v(_vm._s(_vm.shopLoadProgress.text))])]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex align-center",
              staticStyle: { padding: "5px 15px" }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  placeholder: "请输入店铺名称",
                  clearable: ""
                },
                model: {
                  value: _vm.shopQuery.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.shopQuery, "keyword", $$v)
                  },
                  expression: "shopQuery.keyword"
                }
              }),
              _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
                _vm._v("查询")
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex-1 ele-bwc-show-edit__bar",
              staticStyle: { background: "#2EB3F4" }
            },
            [
              _vm.fetchedInfo.completed && !_vm.shopList.length
                ? _c("el-empty", {
                    attrs: {
                      "image-size": 106,
                      description: "没有满足条件的店铺哦"
                    }
                  })
                : _vm._e(),
              _vm._l(_vm.shopList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "d-flex ele-bwc-show-edit__li" },
                  [
                    _c(
                      "div",
                      [
                        _c("img", { attrs: { src: item.shop_logo, alt: "" } }),
                        !item.selected
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { width: "102px" },
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.addShop(item)
                                  }
                                }
                              },
                              [_vm._v("添加")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: { width: "102px" },
                                attrs: { type: "info", plain: "", disabled: "" }
                              },
                              [_vm._v("已添加")]
                            )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex-1",
                        staticStyle: { overflow: "hidden" }
                      },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "white-space": "nowrap",
                              overflow: "hidden",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [
                            _c("span", [_vm._v("店铺名称：")]),
                            _c("strong", { attrs: { title: item.shop_name } }, [
                              _vm._v(_vm._s(item.shop_name))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c("span", [_vm._v("奖励金：")]),
                            _c(
                              "el-tag",
                              {
                                staticStyle: { background: "#FE6300" },
                                attrs: {
                                  type: "danger",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [
                                _vm._v(
                                  "满" +
                                    _vm._s(item.hongbaoCondition) +
                                    "元返" +
                                    _vm._s(item.hongbao) +
                                    "元"
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("p", [_vm._v(_vm._s(item.salesText))]),
                        _c("p", [
                          _c("span", [_vm._v("评分：")]),
                          _c("span", [
                            _vm._v(_vm._s(item.service_rating) + "星")
                          ])
                        ]),
                        _c("p", [
                          _c("span", [_vm._v("起送金额：")]),
                          _c("span", [
                            _vm._v("¥" + _vm._s(item.delivery_price))
                          ])
                        ]),
                        _c("p", [
                          _c("span", [_vm._v("距离：")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(item.delivery_time) +
                                "分钟 " +
                                _vm._s(item.distanceText)
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                )
              })
            ],
            2
          )
        ]),
        _c(
          "section",
          {
            staticClass: "flex-col ele-bwc-show-edit__w",
            staticStyle: { margin: "10px auto 0" }
          },
          [
            _c("iframe", {
              ref: "diyIframe",
              attrs: {
                src: _vm.iframeUrl,
                width: "100%",
                height: "100%",
                frameborder: "0"
              },
              on: { load: _vm.iframeOnLoad }
            })
          ]
        ),
        _c(
          "section",
          {
            staticClass: "flex-col ele-bwc-show-edit__w set",
            staticStyle: { background: "none" }
          },
          [
            _c("div", { staticStyle: { background: "#fff" } }, [
              _c(
                "p",
                {
                  staticStyle: {
                    padding: "10px 15px",
                    "border-bottom": "1px solid #e5e5e5"
                  }
                },
                [_vm._v("专场设置")]
              ),
              _c("div", { staticStyle: { padding: "5px 15px" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center row" },
                  [
                    _c("span", [_vm._v("专场名称：")]),
                    _c("el-input", {
                      staticClass: "flex-1",
                      attrs: {
                        maxlength: 15,
                        "show-word-limit": "",
                        type: "text",
                        placeholder: "请输入专场名称"
                      },
                      model: {
                        value: _vm.setting.name,
                        callback: function($$v) {
                          _vm.$set(_vm.setting, "name", $$v)
                        },
                        expression: "setting.name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center row" },
                  [
                    _c("span", [_vm._v("专场背景：")]),
                    _c("ImageUploader", {
                      staticStyle: { width: "80px" },
                      model: {
                        value: _vm.setting.banner,
                        callback: function($$v) {
                          _vm.$set(_vm.setting, "banner", $$v)
                        },
                        expression: "setting.banner"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          color: "#999",
                          margin: "10px 0 0 70px"
                        }
                      },
                      [_vm._v("图片尺寸750*540px，大小不超过200kb")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center row" },
                  [
                    _c("span", [_vm._v("背景颜色：")]),
                    _c("el-color-picker", {
                      model: {
                        value: _vm.setting.color,
                        callback: function($$v) {
                          _vm.$set(_vm.setting, "color", $$v)
                        },
                        expression: "setting.color"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center row" },
                  [
                    _c("span", [_vm._v("显示更多店铺：")]),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: true },
                        model: {
                          value: _vm.setting.show_more,
                          callback: function($$v) {
                            _vm.$set(_vm.setting, "show_more", $$v)
                          },
                          expression: "setting.show_more"
                        }
                      },
                      [_vm._v("是")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: false },
                        model: {
                          value: _vm.setting.show_more,
                          callback: function($$v) {
                            _vm.$set(_vm.setting, "show_more", $$v)
                          },
                          expression: "setting.show_more"
                        }
                      },
                      [_vm._v("否")]
                    )
                  ],
                  1
                ),
                _vm.setting.show_more
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex align-center row",
                        staticStyle: {
                          "font-size": "12px",
                          background: "#F5F5F5",
                          padding: "10px"
                        }
                      },
                      [
                        _c("span", [_vm._v("定位方式：")]),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            model: {
                              value: _vm.setting.location_way,
                              callback: function($$v) {
                                _vm.$set(_vm.setting, "location_way", $$v)
                              },
                              expression: "setting.location_way"
                            }
                          },
                          [_vm._v("用户定位")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 2 },
                            model: {
                              value: _vm.setting.location_way,
                              callback: function($$v) {
                                _vm.$set(_vm.setting, "location_way", $$v)
                              },
                              expression: "setting.location_way"
                            }
                          },
                          [_vm._v("使用专场定位")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            _vm.selectedItemIndex !== null
              ? _c(
                  "div",
                  { staticStyle: { "margin-top": "15px", background: "#fff" } },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          padding: "10px 15px",
                          "border-bottom": "1px solid #e5e5e5"
                        }
                      },
                      [_vm._v("店铺标签")]
                    ),
                    _c("div", { staticStyle: { padding: "5px 15px" } }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center row" },
                        [
                          _c("span", [_vm._v("标签名称：")]),
                          _c("el-input", {
                            staticClass: "flex-1",
                            attrs: {
                              maxlength: 6,
                              "show-word-limit": "",
                              type: "text",
                              placeholder: "请输入标签内容"
                            },
                            on: { input: _vm.tagInput },
                            model: {
                              value: _vm.shopTag,
                              callback: function($$v) {
                                _vm.shopTag = $$v
                              },
                              expression: "shopTag"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              : _vm._e()
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }